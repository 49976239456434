@tailwind base;
@tailwind components;
@tailwind utilities;
/* Google fonts */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@layer base {
  body {
    @apply font-sans antialiased;
  }
  
  code {
    @apply font-mono;
  }
}

/* styles.css */


.archivo-black-regular {
  font-family: "Archivo Black", sans-serif;
  font-weight: 400;
  font-style: normal;
}